import { Injectable } from '@angular/core';
import { ENV } from 'src/environments/environment';
import { Unit } from 'src/app/model/remote/unit';
import * as PouchDB from 'pouchdb/dist/pouchdb';

@Injectable({
  providedIn: 'root'
})
export class RemoteDBService {

  remoteDB;

  constructor() {
  }

  initializeRemoteDB(remoteSyncUrl: string, remoteUser: string, remotePass: string) {
    this.remoteDB = new PouchDB(remoteSyncUrl, {
      auth: {
        username: remoteUser,
        password: remotePass
      }
    });

    return this.remoteDB;
  }

  getRemoteDB() {
    if (this.remoteDB === undefined) {
      this.initializeRemoteDB(ENV.TGYN_REMOTE_SYNC_URL, ENV.TGYN_REMOTE_USER, ENV.TGYN_REMOTE_PASS);
    }
    return this.remoteDB;
  }

  getRemoteUnits(): Promise<Unit[]> {
    return this.getRemoteDB().allDocs({
      include_docs: true,
      startkey: "unit01",
      endkey: "unit\ufff0"
    })
      .then(result => {
        console.log('getRemoteUnits', result);
        return result.rows.map(row => row.doc).map((unit: Unit) => {
          // unit["lessons"].map((lesson: Lesson) => {
          //   lesson["exercises"].map((exercise: any) => {
          //     // this.getRemoteQuizes(exercise["quizes"]).then(
          //     //   result => (exercise["quizes"] = result)
          //     // );
          //   });
          // });
          return unit;
        });
      })
      .catch((error) => {
        console.log("An error ocurred getting remote units", error);
      });
  }
}
