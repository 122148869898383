import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './shared/guard/auth.guard';

const routes: Routes = [
  // {
  //   path: '',    
  //   loadChildren: () => import('../app/pages/login/login.module').then( m => m.LoginPageModule)
  // },
  {
    path: '', redirectTo: 'home', pathMatch: 'full'    
  },  
  {
    path: 'home',
    loadChildren: () => import('../app/pages/home/home.module').then(m => m.HomePageModule), canActivate: [AuthGuard]
  },
  {
    path: 'login',
    loadChildren: () => import('../app/pages/login/login.module').then(m => m.LoginPageModule)
  }, 
  // {
  //   path: 'home',
  //   loadChildren: () => import('../app/pages/home/home.module').then(m => m.HomePageModule)
  // }
  // {
  //   path: '',
  //   redirectTo: 'login',
  //   pathMatch: 'full'
  // },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
