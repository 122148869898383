import { Component, OnInit } from '@angular/core';
import { NavController, NavParams, MenuController, ModalController, AlertController } from "@ionic/angular";
import { BackendResponse } from '../../model/backend-response';
import { BackendServiceProvider } from "../../services/backendservice/backendservice";
import { Course } from '../../model/course';
import { Observable } from "rxjs";
import { Storage } from '@ionic/storage';
import { SplitScreenProvider } from "../../services/splitscreen/splitscreen";
import { Product } from "../../model/product";

@Component({
  selector: 'page-editcoursealert',
  templateUrl: 'editcoursealert.html',
  styleUrls: ['editcoursealert.scss']
})

export class EditcoursealertPage implements OnInit {
  currentCourse: Course;
  response: any;
  name: any;
  customName: any;
  id: any;
  term: any;
  defaultColor: any = '#fff';
  bunchOfProduct: Product[] = [];
  enableProducts: Product[] = [];
  disableProducts: Product[] = [];
  blueColors: any = '#2699FB';
  courseDataId: any;
  applists: any;
  bunchOfProductsResponse: Product[] = [];
  productUniqueId: any;
  bunchOfProductName: any[] = [];
  bunchOfProductTitle: any[] = [];
  courseUpdatedData: any;
  localFacultyData: any;

  selectedColor: any;

  colors: any[] = [
    { color: "green", code: "#21d521", isSelected: false },
    { color: "orange", code: "#ffba00", isSelected: false },
    { color: "red", code: "#fb263f", isSelected: false },
    { color: "pink", code: "#e226fb", isSelected: false },
    { color: "blue", code: "#2699fb", isSelected: false },
    { color: "grey", code: "#0e5693", isSelected: false },
    { color: "maroon", code: "#8114ba", isSelected: false }]

  classAverage: boolean = false;
  classAverageLabel: string = "Disabled";
  courseStatus: boolean = true;
  wasFormModified: boolean = false;

  constructor(
    public navCtrl: NavController,
    public backendServiceProvider: BackendServiceProvider,
    public storage: Storage,
    public splitPane: SplitScreenProvider,
    public menuCtrl: MenuController,
    // //public event: Events,
    public viewController: ModalController,
    public alertController: AlertController,
    public navParams: NavParams
  ) {




  }

  async ngOnInit() {

    console.log('ionViewDidEnter - editcoursealert');
    this.currentCourse = this.navParams.get('currentCourse');
    console.log('editcoursealert - ionViewDidEnter - currentCourse ' + JSON.stringify(this.currentCourse));

    this.name = this.currentCourse.name;
    this.term = this.currentCourse.term;
    this.customName = this.currentCourse.customName;
    this.classAverage = this.currentCourse.classAverage;
    this.classAverageLabel = this.currentCourse.classAverage ? "Enabled" : "Disabled"
    this.courseStatus = this.currentCourse.isDashboardAdded == 1 ? false : true;

    var colorFound = this.colors.find(color => color.code == this.currentCourse.color);    
    if (colorFound) {
      colorFound.isSelected = true;
      this.selectedColor = colorFound;
    } else {
      // Setting a color by default
      this.selectedColor = this.colors[0];
    }

    this.menuCtrl.enable(true);
    this.menuCtrl.swipeGesture(true);
    //this.splitPane.setSplitPane(true);

    await this.getLocalFacultyData();
  }

  removeAppLink(product: any) {
    console.log('editcoursealert - REMOVE APP LINK', product);
    console.log('editcoursealert - this.enableProducts', this.enableProducts);

    console.log(this.enableProducts.indexOf(product));
    var indexToDelete = this.enableProducts.indexOf(product);

    this.disableProducts.push(this.enableProducts[indexToDelete]);

    this.enableProducts.splice(indexToDelete, 1);

  }

  addAppLink(product: any) {
    console.log('editcoursealert - REMOVE APP LINK', product);
    console.log('editcoursealert - this.enableProducts', this.disableProducts);

    console.log(this.disableProducts.indexOf(product));
    var indexToDelete = this.disableProducts.indexOf(product);

    this.enableProducts.push(this.disableProducts[indexToDelete]);

    this.disableProducts.splice(indexToDelete, 1);

  }

  async openHelpPopup() {

    console.log('Entering to the popup');

    const alert = await this.alertController.create({
      cssClass: 'edit-course-help-popup',
      header: 'Class Averages',
      message: 'Each lesson includes a "Performance Summary" that shows students their percentages of completion, correct responses, etc., for that lesson’s exercises. The <strong>Class Averages</strong> feature allows students to also see the class average for these metrics.',
      buttons: ['OK']
    });

    await alert.present();

  }

  ionViewWillLeave() {
    // this.splitPane.setSplitPane(true);
    this.menuCtrl.enable(true);
    this.menuCtrl.swipeGesture(true);
  }

  async getLocalFacultyData() {

    this.localFacultyData = await this.storage.get("facultyData");
    await this.fetchAppList();

    // .then((val) => {
    //   console.log('EDITCOURSEALERT - getFacultyData: ', val);
    //   this.localFacultyData = val;

    // }).catch(error => {
    //   console.log('error ' + error);

    // });
  }

  async fetchAppList() {
    let collectionOfProduct = [];
    let res;

    let results = await this.backendServiceProvider.getAppList().toPromise();
    //.subscribe(results => {
    if (results.failureData) {
      console.log(results.failureData.message)
    }
    else if (results.success) {
      console.log('EDITCOURSEALERT - fetchAppList - results ' + JSON.stringify(results))
      res = results.data;
      this.bunchOfProduct = res.map(function (currentApp) {

        console.log('app: ' + JSON.stringify(currentApp))
        currentApp.courses = currentApp.courses.filter(function (course) {
          return course.faculty == this.localFacultyData.id;
        }, this);

        return currentApp;

      }, this);

      console.log('EDITCOURSEALERT - fetchAppList - this.bunchOfProduct ' + JSON.stringify(this.bunchOfProduct))

      console.log('editcoursealert - ionViewDidEnter - currentCourse ' + JSON.stringify(this.currentCourse));

      this.currentCourse.courseProducts.forEach(courseProduct => {

        var currentProduct = this.bunchOfProduct.find(product => product.id == courseProduct.product);
        if (courseProduct.enabled) {
          this.enableProducts.push(currentProduct);
        } else {
          this.disableProducts.push(currentProduct);
        }
      });

      // this.bunchOfProduct = res.filter(function (appName) {
      //   console.log('appName.id' + JSON.stringify(appName))
      //   console.log('appName.id' + appName.id)

      //   console.log('localFacultyData: ' + JSON.stringify(this.localFacultyData));



      //   return appName.name, appName.title;

      // }, this).map(function (productAttribute) {

      //   collectionOfProduct.push(productAttribute);
      //   console.log('appNameArray' + JSON.stringify(productAttribute));
      //   return productAttribute;
      // })
    }
    else if (results.networkIssue) {
      console.log('Network Issue');
    }
  }
  //   ,
  //   async error => {
  //   console.log(error);
  //   const alert = await this.alertController.create({
  //     header: "Error",
  //     message: "Sorry, could not load app list",
  //     //enableBackdropDismiss: true,
  //     buttons: [
  //       {
  //         text: 'Cancel',
  //         role: 'cancel'
  //       }
  //     ]
  //   });
  //   alert.present();
  // });
  //   }

  loadProductName(i, productName, productTitle) {
    this.bunchOfProductName = productName;
    this.bunchOfProductTitle = productTitle;
    this.productUniqueId = i;
    this.storage.set('getAppName', this.bunchOfProductName);
    this.storage.set('getAppTitle', this.bunchOfProductTitle);
  }

  isFormModified(): boolean {

    // Cheking if the data was changed
    if (this.currentCourse.term != this.term) {
      return true;
    } else if (this.currentCourse.color != this.selectedColor.code) {
      return true;
    } else if (this.currentCourse.customName != this.customName) {
      return true;
    } else if (this.currentCourse.isDashboardAdded != (this.courseStatus ? 0 : 1)) {
      return true;
    } if (this.currentCourse.classAverage != this.classAverage) {
      return true;
    }

    let modifiedFlag: Boolean = false;
    this.currentCourse.courseProducts.forEach(courseProduct => {
      if (this.enableProducts.find(prod => prod.id == courseProduct.product)) {
        if (courseProduct.enabled != true) {
          modifiedFlag = true;
        }
      } else if (this.disableProducts.find(prod => prod.id == courseProduct.product)) {
        if (courseProduct.enabled != false) {
          modifiedFlag = true;
        }
      }
    });

    if (modifiedFlag) {
      return true;
    }

    return false;
  }

  async closeModal(showDialog: boolean) {

    this.wasFormModified = this.isFormModified();
    console.log('WASFORMMODIFIED', this.wasFormModified);
    if (this.wasFormModified && showDialog) {

      const leaveWithoutSaveAlert = await this.alertController.create({
        cssClass: 'editcourse-save-confirmation-alert',
        // header: 'Alert',
        // subHeader: 'Subtitle',
        message: "Are you sure you want to exit? You haven't saved your changes yet.",
        buttons: [
          {
            text: 'Go back',
            role: 'goBack',
            cssClass: 'secondary',
            handler: () => {
              console.log('Go back pressed');
            }
          }, {
            text: 'Exit',
            role: 'exit',
            cssClass: 'secondary',
            handler: () => {
              this.viewController.dismiss();
            }
          }]
      });

      await leaveWithoutSaveAlert.present();
    } else {
      this.viewController.dismiss();
    }
  }

  updateProductLink() {
    console.log('product id ' + this.productUniqueId);
  }

  selectColor(color) {
    console.log('SELECTING COLOR:......', color);

    if (this.selectedColor != null) {
      this.selectedColor.isSelected = false;
    }

    color.isSelected = true;
    this.selectedColor = color;

    this.defaultColor = this.blueColors;
    this.storage.set('designColor', this.blueColors);
    console.log('tgyn2 ' + this.blueColors);
  }

  classAverageOnChange(event) {
    let toggleValue = event.detail.checked;
    this.classAverageLabel = toggleValue ? "Enabled" : "Disabled"
  }

  onChangeForm(event) {
    console.log("Something changed in the form");
    console.log(event);
  }

  // this.storage.set('getAppName', this.bunchOfProductName);
  //        this.storage.set('getAppTitle', this.bunchOfProductTitle);

  updateCourse(courseName: string, courseTerm: string) {

    console.log('updateCourse - courseName & courseTerm ', this.currentCourse);
    console.log('updateCourse - courseName & courseTerm ', this.customName);
    console.log('updateCourse - CLASSAVERGAE ', this.classAverage);
    console.log('updateCourse - COURSESTATUS ', this.courseStatus);
    console.log('updateCourse - this.selectedColor ', this.selectedColor);    

    this.currentCourse.term = courseTerm;
    this.currentCourse.color = this.selectedColor.code;
    this.currentCourse.faculty = this.currentCourse.faculty;
    this.currentCourse.customName = this.customName;
    this.currentCourse.classAverage = this.classAverage;
    this.currentCourse.isDashboardAdded = this.courseStatus ? 0 : 1;

    this.currentCourse.courseProducts.forEach(courseProduct => {
      if (this.enableProducts.find(prod => prod.id == courseProduct.product)) {
        courseProduct.enabled = true;
      } else if (this.disableProducts.find(prod => prod.id == courseProduct.product)) {
        courseProduct.enabled = false;
      }
    });

    var temporalCourseProduct = this.currentCourse.courseProducts;
    // this.currentCourse.courseProducts = this.currentCourse.courseProducts.map(courseProduct => { 
    //   return { id: courseProduct.id, enabled: courseProduct.enabled } 
    // });
    this.currentCourse.courseProducts = undefined;
    this.currentCourse.products = undefined;
    let updateCourseAction: Observable<BackendResponse>;
    //update course 
    updateCourseAction = this.backendServiceProvider.updateCourse(this.currentCourse);

    updateCourseAction.subscribe(data => {

      if (data.failureData) {
        console.log(data.failureData.message);
      }
      else if (data.success) {
        this.courseUpdatedData = data.data;
        console.log('AFTER SUCCESS - temporalCourseProduct', temporalCourseProduct);
        this.backendServiceProvider.updateCourseProduct(temporalCourseProduct).subscribe(result => {

          console.log('updateCourseProduct - RESULT - ', result);
          //responseAddCourseData = this.courseUpdatedData;
          this.currentCourse.courseProducts = temporalCourseProduct;
          this.storage.set('CourseResponse', this.courseUpdatedData);
          this.closeModal(false);
        });

      }
      else if (data.networkIssue) {
        console.log('Network Issue');
      }
    })
  }
}
