import {User} from "./user";
import {Course} from "./course";
import {Institution} from "./institution";

export class Faculty extends User{

    _id: string;
    id: number;
    firstName: string = undefined;
    middleName: string = undefined;
    lastName: string = undefined;
    phone: string = undefined;
    email: string = undefined;
    password: string = undefined;
    courses: Course[];
    institution: Institution;
}
