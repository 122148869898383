import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Faculty } from 'src/app/model/faculty';
import { Storage } from '@ionic/storage';

@Injectable()
export class SplitScreenProvider {

  splitPaneState: boolean;
  facultyUserId: any;

  constructor(public platform: Platform, public storage: Storage) {
    console.log('Hello SplitpaneProvider Provider');
    this.splitPaneState = false;
  }

  getSplitPane() {    
    if (localStorage.getItem('loginResponse')) {
      this.splitPaneState = true;
    } else {
      this.splitPaneState = false;
    }
    return this.splitPaneState;
  }

  getFacultyDataById(): Faculty {    
    var loginResponse = JSON.parse(localStorage.getItem('loginResponse'));
    let faculty = new Faculty();
    if (loginResponse) {      
      faculty.firstName = loginResponse.firstName.charAt(0).toUpperCase() + loginResponse.firstName.slice(1);
      faculty.lastName = loginResponse.lastName.charAt(0).toUpperCase() + loginResponse.lastName.slice(1);
    } else {
      faculty.firstName = "First Name";
      faculty.lastName = "Last Name";
    }

    return faculty;
  }
}
