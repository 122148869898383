import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router'
import { ReactiveFormsModule, FormsModule } from '@angular/forms'
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { HttpClientModule } from '@angular/common/http';
import { EditcoursealertPage } from './editcoursealert';
import { BackendServiceProvider } from 'src/app/services/backendservice/backendservice';

const routes: Routes = [
  {
    path: '',
    component: EditcoursealertPage
  }
];

@NgModule({
  declarations: [
    EditcoursealertPage
  ],
  imports: [    
    //HttpClientModule,
    CommonModule,
    IonicModule,
    FormsModule,
    RouterModule.forChild(routes),
  ],  
  providers: [    
    BackendServiceProvider
  ]
  
})
export class EditcoursealertPageModule { }

