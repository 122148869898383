
import { throwError as observableThrowError, Observable, of, BehaviorSubject } from 'rxjs';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { Course } from '../../model/course';
import { Faculty } from "../../model/faculty";
import { ENV } from "../../../environments/environment";
import { tap, catchError, map } from 'rxjs/operators';
import { SalesRep } from '../../model/sails-rep';
import { Admin } from '../../model/admin';
import { BackendResponse } from '../../model/backend-response';
import { LoginData } from "../../model/login-data";
import { Product } from "../../model/product";
import { CourseProduct } from 'src/app/model/courseProduct';

/*
  Generated class for the BackendServiceProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
const FACULTY = 'faculty';
const COURSE_NAME = 'course';
const SALES_REP = 'salesrep';
const STUDENT = 'student';
const ADMIN = 'admin';

const BACKEND_SERVER_NAME = ENV.SERVER_NAME;
const CREATE_ADMIN_URL = ENV.SERVER_NAME + 'api/v1/entrance/signup';
const LOGIN_ADMIN_URL = BACKEND_SERVER_NAME + 'api/v1/entrance/login';

const FACULTY_DATA = BACKEND_SERVER_NAME + 'faculty';
const FACULTY_SIGNUP_URL = BACKEND_SERVER_NAME + 'api/v1/entrance/signup';
const FACULTY_LOGIN_URL = BACKEND_SERVER_NAME + 'api/v1/entrance/login';
const FACULTY_UPDATE_URL = BACKEND_SERVER_NAME + FACULTY + '/update';

const CREATE_SALES_REP_URL = BACKEND_SERVER_NAME + 'api/v1/entrance/signup';
const LOGIN_SALES_REP_URL = BACKEND_SERVER_NAME + 'api/v1/entrance/login';

// QUIZ
const QUIZ_URL = BACKEND_SERVER_NAME + 'quizstat';

const COURSE_NAME_URL = BACKEND_SERVER_NAME + 'course/';
const COURSE_ADD_URL = BACKEND_SERVER_NAME + COURSE_NAME + '/create';
const COURSE_UPDATE_URL = BACKEND_SERVER_NAME + "course/update";
const COURSE_DATA_BY_ID = BACKEND_SERVER_NAME + "course/findone";

// COURSE PRODUCT
const COURSE_PRODUCT_URL = BACKEND_SERVER_NAME + 'courseproduct/';

//CODE
const CODE_CREATE_URL = BACKEND_SERVER_NAME + "code";

const STUDENT_PROFILE_DATA = BACKEND_SERVER_NAME + "student/findone";
const STATS_DATA_URL = BACKEND_SERVER_NAME + "stats/find";
const STUDENT_FLAG_DATA_URL = BACKEND_SERVER_NAME + "student/flag-data";
const FACULTY_DATA_BY_ID = BACKEND_SERVER_NAME + "faculty/findone";
const INSTITUTION_DATA_BY_ID = BACKEND_SERVER_NAME + "institution/findone";

const FACULTY_PASSWORD_RESET_EMAIL = BACKEND_SERVER_NAME + "api/v1/entrance/send-password-recovery-email";
const RESET_PASSWORD_WITH_TOKEN_URL = BACKEND_SERVER_NAME + "api/v1/entrance/update-password-and-login";

const APP_LIST = BACKEND_SERVER_NAME + "product";

@Injectable()
export class BackendServiceProvider {

  private loggedIn = false;
  private userType = FACULTY;
  private admin = false;

  constructor(public http: HttpClient, public toastController: ToastController) {
    console.log('Hello BackendServiceProvider Provider');
  }

  //this method is applicable for multi user type signup operation
  private createUser(url: string, user: Admin | Faculty | SalesRep, userType: string): Observable<BackendResponse> {
    return this.http.post<BackendResponse>(url, Object.assign(user, { userType: userType }), { withCredentials: true })
      .pipe(
        tap((data: BackendResponse) => {
          if (data.success === true) {
            this.loggedIn = true;
            this.userType = userType;
          }
        }),
        catchError(this.handleError('create' + userType, <BackendResponse>{ success: false }))
      );
  }

  resetPassword(token: string, password: string): Observable<BackendResponse> {
    return this.http.post<BackendResponse>(RESET_PASSWORD_WITH_TOKEN_URL,
      { token: token, password: password }, { withCredentials: true })
      .pipe(
        tap(data => {
          if (data['token']) {
            return <BackendResponse>{ success: true };
          }
        }),
        catchError(this.handleError('password reset failure', <BackendResponse>{ success: false }))
      )
  }

  passwordResetEmail(url: string, email: string): Observable<BackendResponse> {
    return this.http.post<boolean>(url, { email: email }, { withCredentials: true })
      .pipe(
        map(result => {
          return <BackendResponse>{ success: result }
        }),
        catchError(this.handleError('password reset request', <BackendResponse>{ success: false }))
      );
  }

  updatePassword(FACULTY_DATA: string, user: Faculty, userType: string): Observable<BackendResponse> {
    return this.http.patch<boolean>(FACULTY_DATA + "/" + user.email, user, { withCredentials: true })
      .pipe(
        map(result => {
          return <BackendResponse>{ success: result }
        }),
        tap((data: BackendResponse) => {
          if (data.success === true) {
            this.loggedIn = true;
            this.userType = userType;
          }
        }),
        catchError(this.handleError('create' + userType, <BackendResponse>{ success: false }))
      );
  }


  //this method is applicable for multiuser type login operation
  private loginUser(url: string, loginData: Faculty | LoginData | SalesRep, userType: string): Observable<BackendResponse> {
    return this.http.put<boolean>(url, Object.assign(loginData, { userType: userType }), { withCredentials: true })
      .pipe(
        map(result => {
          return <BackendResponse>{ success: result }
        }),
        tap((data: BackendResponse) => {
          if (data.success === true) {
            this.loggedIn = true;
            this.userType = userType;
            this.admin = data.data['admin'];
          }
        }),
        catchError(this.handleError('login' + userType, <BackendResponse>{ success: false }))
      );
  }

  //add class using observable

  // createCourse(courseData: Course): Observable<Course> {
  //   let header = new HttpHeaders({ 'Content-type': 'application/json' });
  //   // let options = new HttpHeaders({headers : header});
  //   return this.http
  //     .post(COURSE_ADD_URL, courseData, { headers: header })
  //     .map(success => {
  //       return success;
  //     })
  //     .catch(this.handleErrors)
  // }

  private createCourseData(COURSE_ADD_URL: string, courseData: Course, userType: string): Observable<BackendResponse> {
    return this.http.post<boolean>(COURSE_ADD_URL, courseData, { withCredentials: true })
      .pipe(
        map(result => {
          return <BackendResponse>{ success: result }
        }),
        tap((data: BackendResponse) => {
          if (data.success === true) {
            this.userType = userType;
          }
        }),
        catchError(this.handleError('course' + userType, <BackendResponse>{ success: false }))
      )
  }

  //update course

  // private updateCourseData(url: string, courseData: Course): Observable<BackendResponse> {
  //   console.log('url ' + url)
  //   return this.http
  //     .patch<boolean>(url , courseData, { withCredentials: true })
  //     .pipe(
  //       map(result => {
  //         return <BackendResponse>{ success: true, data: result }
  //       }),
  //       tap((data: BackendResponse) => {
  //         if (data.success === true) {
  //           this.loggedIn = true;
  //         }
  //       }),
  //       catchError(this.handleError('course update', <BackendResponse>{ success: false }))
  //     )
  // }

  //update course
  private updateCourseData(COURSE_NAME_URL: string, courseData: Course): Observable<BackendResponse> {
    let data: any;
    data = courseData;
    /*
    map product Id with the course
    add product into the course by product id 
    */
    if (courseData.products && courseData.products.length > 0) {
      data.products = courseData.products.map(p => p.id);
    }

    if (courseData.faculty != null) {
      data.faculty = courseData.faculty.id;
    }

    return this.http
      .patch<boolean>(COURSE_NAME_URL, courseData, { withCredentials: true })
      .pipe(
        map(result => {
          return <BackendResponse>{ success: true, data: result }
        }),
        catchError(this.handleError('course update', <BackendResponse>{ success: false }))
      )
  }

  //update course
  private updateCourseProductData(URL: string, courseProductData: CourseProduct[]): Observable<BackendResponse> {
    return this.http
      .post<boolean>(URL, courseProductData, { withCredentials: true })
      .pipe(
        map(result => {
          console.log("BACKEND RESPONSE", result);
          return <BackendResponse>{ success: true, data: result }
        }),
        catchError(this.handleError('Course Product Update', <BackendResponse>{ success: false }))
      )
  }

  //delete class
  deleteCourseData(COURSE_NAME_URL: string, courseId: number, userType: string): Observable<BackendResponse> {
    console.log('Class ID: ' + courseId);
    return this.http
      .delete<boolean>(COURSE_NAME_URL + courseId, { withCredentials: true })
      .pipe(
        map(result => {
          return <BackendResponse>{ success: result }
        }),
        tap((data: BackendResponse) => {
          if (data.success === true) {
            this.userType = userType;
          }
        }),
        catchError(this.handleError('course' + userType, <BackendResponse>{ success: false }))
      )
  }

  deleteStudentFromCourseProduct(COURSE_NAME_URL: string, courseProductId: number, studentId: number): Observable<BackendResponse> {
    console.log('studentId ' + studentId);
    var deleteURL = `${COURSE_NAME_URL}${courseProductId}/students/${studentId}`
    console.log('deleteURL ' + deleteURL);
    return this.http.delete<boolean>(deleteURL, { withCredentials: true })
      .pipe(
        map(result => {
          return <BackendResponse>{ success: true, data: result }
        }),
        catchError(this.handleError('DELETE student from COURSE', <BackendResponse>{ success: false }))
      )
  }

  getFacultyDataById(url: string, userType: string): Observable<BackendResponse> {
    return this.http.get<boolean>(url, { withCredentials: true })
      .pipe(
        map(result => {
          return <BackendResponse>{ success: result }
        }),
        tap((data: BackendResponse) => {
          if (data.success === true) {
            this.loggedIn = true;
            this.userType = userType;
            this.admin = data.data['faculty'];
          }
        }),
        catchError(this.handleError('login' + userType, <BackendResponse>{ success: false }))
      );
  }

  getInstitutionProfileById(url: string, id: number): Observable<BackendResponse> {
    return this.http.get<boolean>(url + '?userId=' + id, { withCredentials: true })
      .pipe(
        map(result => {
          return <BackendResponse>{ success: true, data: result }
        }),
        catchError(this.handleError('institution', <BackendResponse>{ success: false }))
      );
  }

  // COURSE CALLS
  getCourseDataById(url: string, id: number): Observable<BackendResponse> {
    return this.http.get<boolean>(url + '?courseId=' + id, { withCredentials: true })
      .pipe(
        map(result => {
          return <BackendResponse>{ success: true, data: result }
        }),
        catchError(this.handleError('course', <BackendResponse>{ success: false }))
      );
  }

  getCoursesDataByFaculty(url: string, facultyId: number, courseStatus: boolean): Observable<BackendResponse> {
    console.log("Testing call to getCoursesDataByFaculty");
    console.log(`${url}?faculty=${facultyId}&isDashboardAdded=${courseStatus}`);
    return this.http.get<boolean>(`${url}?faculty=${facultyId}&isDashboardAdded=${courseStatus}`, { withCredentials: true })
      .pipe(
        map(result => {
          return <BackendResponse>{ success: true, data: result }
        }),
        catchError(this.handleError('course', <BackendResponse>{ success: false }))
      );
  }

  // COURSE PRODUCT DATA
  getCoursesProductData(url: string, courseProductId: number): Observable<BackendResponse> {
    return this.http.get<boolean>(`${url}${courseProductId}`, { withCredentials: true })
      .pipe(
        map(result => {
          return <BackendResponse>{ success: true, data: result }
        }),
        catchError(this.handleError('course', <BackendResponse>{ success: false }))
      );
  }


  //get Student Profile data
  getStudentProfileById(url: string, id: number, userType: string): Observable<BackendResponse> {
    return this.http.get<boolean>(url + '?userId=' + id, { withCredentials: true })
      .pipe(
        map(result => {
          return <BackendResponse>{ success: true, data: result }
        }),
        catchError(this.handleError('login' + userType, <BackendResponse>{ success: false }))
      );
  }

  getCourseProductStats(courseId: number, productId: number, studentId: number): Observable<BackendResponse> {
    var parameters = `?courseId=${courseId}&productId=${productId}&customReport=false`;
    if (studentId) {
      parameters = parameters + '&studentId=' + studentId;
    }
    return this.http.post<boolean>(STATS_DATA_URL + parameters, { withCredentials: true })
      .pipe(
        map(result => {
          return <BackendResponse>{ success: true, data: result }
        }),
        catchError(this.handleError('Stats failed', <BackendResponse>{ success: false }))
      );
  }

  getCustomReportStatsData(courseId: number, productId: number): Observable<BackendResponse> {
    var parameters = `?courseId=${courseId}&productId=${productId}&customReport=true`;
    return this.http.post<boolean>(STATS_DATA_URL + parameters, { withCredentials: true })
      .pipe(
        map(result => {
          return <BackendResponse>{ success: true, data: result }
        }),
        catchError(this.handleError('Custom Report Stats failed', <BackendResponse>{ success: false }))
      );
  }

  getStudentFlagData(courseId: number, productId: number): Observable<BackendResponse> {
    var parameters = '?courseId=' + courseId + '&productId=' + productId;
    return this.http.get<boolean>(STUDENT_FLAG_DATA_URL + parameters, { withCredentials: true })
      .pipe(
        map(result => {
          return <BackendResponse>{ success: true, data: result }
        }),
        catchError(this.handleError('Student Flag Data Failed', <BackendResponse>{ success: false }))
      );
  }

  updateQuizStat(quizId: number, quizToUpdate: any): Observable<BackendResponse> {
    return this.http.patch<boolean>(QUIZ_URL + "/" + quizId, quizToUpdate, { withCredentials: true })
      .pipe(
        map(result => {
          return <BackendResponse>{ success: result }
        }),
        catchError(this.handleError('Updated Quiz Stat Failed', <BackendResponse>{ success: false }))
      );
  }

  // getStudentProfile():Observable<Student[]> {
  //   return this.http
  //   .get(STUDENT_PROFILE_DATA)
  //   .map((response: HttpResponse<Student>) => {
  //     return response;
  //   })
  //   .catch(this.handleErrors);
  // }

  //display data using observable
  getCourseData(course: Course): Observable<Course> {
    return this.http
      .get<Course>(COURSE_NAME_URL + '?id=' + course.id, { withCredentials: true })
      .pipe(
        catchError(this.handleErrors)
      );
  }

  getAllCourses(): Observable<Course[]> {
    return this.http
      .get<Course[]>(COURSE_NAME_URL, { withCredentials: true })
      .pipe(
        catchError(this.handleErrors)
      );
  }

  //get faculty data using observable
  getFacultyData(): Observable<Faculty[]> {
    return this.http
      .get<Faculty[]>(FACULTY_DATA, { withCredentials: true })
      .pipe(
        catchError(this.handleErrors)
      );
  }

  //CODE calls
  private createCodeCall(CODE_ADD_URL: string, courseData: Course): Observable<BackendResponse> {

    return this.http.post<boolean>(CODE_ADD_URL + '?codeType=CLASSCODE&courseId=' + courseData.id, { withCredentials: true })
      .pipe(
        map(result => {
          return <BackendResponse>{ success: true, data: result }
        }),
        catchError(this.handleError('CREATE CODE', <BackendResponse>{ success: false }))
      )
  }


  private handleErrors<BackendResponse>(error: Response) {
    return observableThrowError(error.statusText);

  }


  //facultyLogin via observable
  //this method is aaplicable for single login.
  // facultyLogin(facultyLoginData : Faculty): Observable<Faculty>{
  //   let header = new HttpHeaders({'Content-type' : 'application/json'});
  //   //let options = new RequestOptions({headers : header});
  //   return this.http
  //   .post(FACULTY_LOGIN_URL, facultyLoginData , {headers: header})
  //   .map(success => {
  //     return success;
  //   })
  //   .catch(this.handleErrors)
  // }

  //this signup method is applicable for single login

  // facultySignup(facultySignupData : Faculty): Observable<Faculty> {
  //   let header = new HttpHeaders({'Content-type' : 'application/json'});
  //   return this.http
  //   .post(FACULTY_SIGNUP_URL, facultySignupData, {headers: header})
  //   .map(success => {
  //     return success;
  //   })
  //   .catch(this.handleErrors);
  // }

  createAdmin(admin: Admin): Observable<BackendResponse> {
    return this.createUser(CREATE_ADMIN_URL, admin, ADMIN);
  }

  loginAdmin(loginData: LoginData): Observable<BackendResponse> {
    return this.loginUser(LOGIN_ADMIN_URL, loginData, ADMIN);
  }

  createFaculty(faculty: Faculty): Observable<BackendResponse> {
    return this.createUser(FACULTY_SIGNUP_URL, faculty, FACULTY)
  }

  sendPasswordResetEmail(email: string): Observable<BackendResponse> {
    return this.passwordResetEmail(FACULTY_PASSWORD_RESET_EMAIL, email);
  }

  updateFacultyPassword(faculty: Faculty): Observable<BackendResponse> {
    return this.updatePassword(FACULTY_DATA, faculty, FACULTY);
  }

  loginFaculty(faculty: Faculty): Observable<BackendResponse> {
    return this.loginUser(FACULTY_LOGIN_URL, faculty, FACULTY);
  }

  createSalesRep(rep: SalesRep): Observable<BackendResponse> {
    return this.createUser(CREATE_SALES_REP_URL, rep, SALES_REP);
  }

  loginSalesRep(salesRep: SalesRep): Observable<BackendResponse> {
    return this.loginUser(LOGIN_SALES_REP_URL, salesRep, SALES_REP);
  }

  createCourse(courseData: Course): Observable<BackendResponse> {
    return this.createCourseData(COURSE_ADD_URL, courseData, FACULTY);
  }

  // updateCourse(courseData: Course, courseId: number): Observable<BackendResponse> {
  //   console.log('courseId ' + courseId)
  //   return this.updateCourseData(COURSE_UPDATE_URL + '?id=' + courseId, courseData );
  // }

  updateCourse(courseData: Course): Observable<BackendResponse> {
    return this.updateCourseData(COURSE_NAME_URL, courseData);
  }

  deleteCourse(courseData: number): Observable<BackendResponse> {
    return this.deleteCourseData(COURSE_NAME_URL, courseData, FACULTY);
  }

  getCourseProductById(courseProductId: number): Observable<BackendResponse> {
    return this.getCoursesProductData(COURSE_PRODUCT_URL, courseProductId);
  }

  deleteStudentFromCourse(courseProductId: number, studentId: number): Observable<BackendResponse> {
    return this.deleteStudentFromCourseProduct(COURSE_PRODUCT_URL, courseProductId, studentId);
  }

  updateCourseProduct(courseProductData: CourseProduct[]): Observable<BackendResponse> {
    return this.updateCourseProductData(COURSE_PRODUCT_URL, courseProductData);
  }

  getFacultyDataId(facultyId: number): Observable<BackendResponse> {
    return this.getFacultyDataById(FACULTY_DATA_BY_ID + '?userId=' + facultyId, FACULTY);
  }

  // COURSE SECTION
  getCourseDataId(courseId: number): Observable<BackendResponse> {
    return this.getCourseDataById(COURSE_DATA_BY_ID, courseId);
  }

  getCoursesData(facultyId: number, courseStatus: boolean): Observable<BackendResponse> {
    return this.getCoursesDataByFaculty(COURSE_NAME_URL, facultyId, courseStatus);
  }

  getInstitutionDataId(institutionId: number): Observable<BackendResponse> {
    return this.getInstitutionProfileById(INSTITUTION_DATA_BY_ID, institutionId);
  }

  getStudentProfile(studentId: number): Observable<BackendResponse> {
    return this.getStudentProfileById(STUDENT_PROFILE_DATA, studentId, STUDENT);
  }

  getAppList(): Observable<BackendResponse> {
    return this.http.get<Product>(APP_LIST, { withCredentials: true }).pipe(
      map(result => {
        return <BackendResponse>{ success: true, data: result }
      }),
      catchError(this.handleError('getAppList', <BackendResponse>{ success: false }))
    );
  }

  isLoggedIn(): boolean {
    return this.loggedIn;
  }

  isFaculty(): boolean {
    return this.isLoggedIn() && this.userType === FACULTY;
  }

  isSalesRep(): boolean {
    return this.isLoggedIn() && this.userType === SALES_REP;
  }

  //CODE calls
  createCode(courseData: Course): Observable<BackendResponse> {
    return this.createCodeCall(CODE_CREATE_URL, courseData);
  }



  /**
   * Handle Http operation that failed.
   * Let the app continue.  Give a simply BackendResponse object to ensure that view can be updated
   * depending on the status.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<BackendResponse>(operation = 'operation', result?: BackendResponse) {
    return (error: any): Observable<BackendResponse> => {

      // TODO: send the error to remote logging infrastructure
      console.error('ERROR', error); // log to console instead
      let toastMessage = ' ';

      console.log(`${operation} failed: ${error.message}`);

      if (error.status === 500) {
        result['operationDenied'] = true;
        toastMessage = 'Error getting data from server.';
      }
      else if (error.status === 401) {
        result['accessDenied'] = true;
        result['data'] = error.error;
        toastMessage = 'Access is denied. Add Valid Email Address.';
      }
      else if (error.error.code == 'E_UNIQUE') {
        result['notUnique'] = true;
        result['data'] = error.error;
        toastMessage = 'Sorry, You have added same data.  Please add unique data';
      }
      else if (error.status === 400) {
        result['notUnique'] = true;
        result['data'] = error.error;
        toastMessage = 'Sorry, You have added incorrect data.  Please add correct data';
      }

      else if (error.status === 403) {
        result['accessDenied'] = true;
        toastMessage = 'Access is denied.  Please contact us for further support.';
      }
      else if (error.status == 498) {
        result['notUnique'] = true;
        result['data'] = error.error;
        toastMessage = 'Seems like this account already exists.  Please login instead.';
      }
      else if (error.status == 409 || (error.error && typeof (error.error.toLowerCase) === 'function' &&
        error.error.toLowerCase().includes('unique') > -1)) {
        result['notUnique'] = true;
        if (operation.includes("login")) {
          toastMessage = 'Could not login. Username or password is incorrect.';
        }
        else {
          toastMessage = 'Seems like this account already exists.  Please login instead.';
        }
      }
      else if (error.error && error.error instanceof Error) {
        // A client-side or network error occurred. Handle it accordingly.
        console.log('An error occurred:', error.message);
        result['networkIssue'] = true;
        toastMessage = 'Network error in communicating with our server.';
      }
      else {
        // The backend returned an unsuccessful response code.
        // The response body may contain clues as to what went wrong,
        console.log(`Backend returned code ${error.status}, body was: `);
        if (error.error) {


          result['failureData'] = error.error;
          if (result['failureData'].message === "Email not found.") {
            result['Email_not_found'] = true;
          }
          console.log(result['failureData'].message);
          if (error.error.code) {
            switch (error.error.code) {
              case 'E_MISSING_OR_INVALID_PARAMS':
                result['validationFailures'] = error.error.problems;
            }
          }
        }
        result['operationFailed'] = true;
      }

      this.presentToast(toastMessage);

      // Let the app keep running by returning an empty result.
      return of(result);
    };
  }

  async presentToast(toastMessage: string) {

    const toast = await this.toastController.create({
      message: toastMessage,
      duration: 3000,
      position: 'bottom'
    });

    toast.present();

    // const toast = await this.toastController.create({
    //   message: 'Your settings have been saved.',
    //   duration: 2000
    // });
    // toast.present();
  }


  //faculty login using promise
  // facultySignIn(Faculty){
  //   return new Promise((resolve,reject) => {
  //     console.log(Faculty);
  //     let headers = new Headers();
  //     headers.append('content-Type' , 'application/json');
  //     this.http.post(FACULTY_LOGIN_URL, JSON.stringify(Faculty))
  //     .subscribe(res => {
  //       console.log(res);
  //     },(err) => {
  //       reject(err)
  //     });
  //   })
  //   .catch(() => {
  //     this.toastController.create({
  //       message : "sorry could not login",
  //     })
  //   })
  // }
  //add class using promise
  // addClass(ClassData){
  //   return new Promise((resolve,reject) => {
  //     console.log(ClassData)
  //     this.http.post(CLASS_NAME_URL,JSON.stringify(ClassData))
  //     .subscribe(res=> {
  //       console.log(res);
  //     }, (err) => {
  //       reject(err)
  //     });
  //   })
  //   .catch(() => {
  //     this.toastController.create({
  //       message: 'Sorry, could not add data.',
  //       duration: 1000
  //     })
  //   });
  // }

  //display data using promise
  // displayClass(ClassData){

  //   return new Promise((resolve,reject) => {

  //     this.http.get(CLASS_NAME_URL,JSON.stringify(ClassData))
  //     .subscribe(
  //       res =>{
  //         ClassData = res.json();
  //         console.log(ClassData);
  //       },
  //       (err) =>{
  //         console.log(err);
  //       });
  //     })
  //   .catch(() => {
  //     this.toastController.create({
  //       message: 'Sorry, could not load Data.',
  //       duration: 1000
  //     })
  //   });
  // }
}
