import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, NgModule } from '@angular/core';
import { IonicModule, IonicRouteStrategy } from "@ionic/angular";

import { BackendServiceProvider } from "../app/services/backendservice/backendservice";
import { SplitScreenProvider } from "../app/services/splitscreen/splitscreen";
import { IonicStorageModule } from '@ionic/storage';

import { MyApp } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { EditcoursealertPageModule } from './pages/editcoursealert/editcoursealert.module';
import { RemoteDBService } from './services/remotedb/remote-db.service';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';


@NgModule({
    declarations: [MyApp],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        EditcoursealertPageModule,
        IonicModule.forRoot(),
        IonicStorageModule.forRoot({
            name: 'myDB',
            driverOrder: ['indexeddb', 'sqlite', 'websql']
        })
    ],
    providers: [
        BackendServiceProvider,
        RemoteDBService,
        //{ provide: ErrorHandler, useClass: IonicErrorHandler },    
        SplitScreenProvider,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        InAppBrowser
    ],
    bootstrap: [MyApp]
})
export class AppModule { }
