import { Storage } from '@ionic/storage';
import { Component, ViewChild } from '@angular/core';
import { Platform, MenuController, NavController, ToastController } from "@ionic/angular";
import { SplitScreenProvider } from "../app/services/splitscreen/splitscreen";
import { Faculty } from './model/faculty';
import { Observable } from 'rxjs';
import { BackendResponse } from './model/backend-response';
import { BackendServiceProvider } from './services/backendservice/backendservice';
import { Institution } from './model/institution';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})

export class MyApp {

  iconsWithTitleMenu: boolean = true;
  iconsMenu: boolean = false;
  facultyData: any;
  facultyStorageResponse: any;
  institutionData: any[] = [];
  institutionStorageResponse: any;
  facultyUserId: any;
  institutionUserId: any;
  facultyFirstName: any;
  facultyLastName: any;
  headerTitle: boolean = true;
  adminDashboardTitleWithMenu: boolean = false;
  removeDashTitle: boolean = true;
  isLoggedIn = false;

  rootPage: any = 'LoginPage';

  constructor(
    public platform: Platform,
    public splitPane: SplitScreenProvider,
    public menu: MenuController,
    public nav: NavController,
    public toastController: ToastController,
    public backendServiceProvider: BackendServiceProvider,
    public storage: Storage) {

    console.log("Entering to the appComponent.ts - CONSTRUCTOR");

    this.eventSubscribe();
    this.getInstitutionDataById();
    this.getFacultyDataById();
    // this.addCoursePage();
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      // Okay, so the platform is ready and our plugins are available.
      // Here you can do any higher level native things you might need.
      // this.statusBar.styleDefault();
      // this.splashScreen.hide();
    });
  }

  eventSubscribe() {
    if (localStorage.getItem('iconMenu') === "true") {
      this.iconsWithTitleMenu = false;
      //this.adminDashboardTitleWithMenu = false;
      this.iconsMenu = true;
      //when subscrivbe the event than root page is Metrics page
      // this.rootPage = 'MetricsdataPage';
    }

    //subscribe course-list page event and listen user created event after course-list page's funtion is called
    //iconMenu is a key which get from iconMenuCtrl of courslist function
    // this.event.subscribe('iconMenu', (iconMenu, time) => {
    //   console.log('time' + time + 'iconmenu' + iconMenu);
    //   //go to metrics page and disable icon-with-title menu and enable icon-menu
    //   if (iconMenu) {

    //     //when publish the event icon-with-title is disabled and icon-menu is enabled
    //     this.iconsWithTitleMenu = false;
    //     //this.adminDashboardTitleWithMenu = false;
    //     this.iconsMenu = true;
    //     //when subscrivbe the event than root page is Metrics page
    //     // this.rootPage = 'MetricsdataPage';
    //   }

    // })
    //courseListPageMenu event
    // this.event.subscribe('courseMenu', (courseMenu, time) => {
    //   console.log('time' + time + 'iconmenu' + courseMenu);
    //   //go to metrics page and disable icon-with-title menu and enable icon-menu
    //   if (courseMenu) {
    //     //when publish the event icon-with-title is disabled and icon-menu is enabled
    //     this.iconsWithTitleMenu = true;
    //     //this.adminDashboardTitleWithMenu = false;
    //     this.iconsMenu = false;
    //     //when subscrivbe the event than root page is Metrics page
    //   }
    // })
  }
  //   //admin login menu event
  //  loginEventSubscribe(){
  //   this.event.subscribe('AdminDashboardMenu' , (AdminDashboardMenu, time) => {
  //     console.log('time' + time + 'iconmenu' + AdminDashboardMenu);
  //     //go to metrics page and disable icon-with-title menu and enable icon-menu
  //     if(AdminDashboardMenu){
  //       //when publish the event icon-with-title is disabled and icon-menu is enabled
  //       this.iconsWithTitleMenu = false;
  //       this.iconsMenu = false;
  //       this.adminDashboardTitleWithMenu = true;
  //       //when subscrivbe the event than root page is Metrics page
  //       this.rootPage = 'AdminDashBoardPage';
  //     }

  //   })
  // }
  // loginEventUnsubscribe(){
  //    //subscribe course-list page event and listen user created event after course-list page's funtion is called
  //    this.event.unsubscribe('AdminDashboardMenu')
  //    this.iconsWithTitleMenu = false;
  //    this.iconsMenu = false;
  //    this.adminDashboardTitleWithMenu = false;

  //    //when unsubscribe the page then root page is also as metrics page
  //    this.rootPage = 'AdminDashBoardPage';


  // }
  openPage(page) {
    // Reset the content nav to have just this page
    // we wouldn't want the back button to show in this scenario
    this.nav.navigateRoot(page.component);
  }

  iconMenuCtrl(courseMenu) {
    //this.event.publish('courseMenu', courseMenu, Date.now);

    console.log(courseMenu);
  }

  dashboradPage() {
    // this.navCtrl.pop();
    this.iconMenuCtrl("courseMenu");
    //localStorage.setItem("courseMenu" , "false");
    this.nav.navigateRoot('DashboardPage');
  }

  addNewCourse() {
    this.nav.navigateRoot('AddCoursePage');
  }

  

  courceListPage() {
    this.removeDashTitle;
    // this.nav.navigateRoot('CourseListPage', {
    //   'removeDashTitle': this.removeDashTitle
    // });
  }

  menuIcon() {
    //first unsubscribe previous event
    this.iconsMenu = false;
    this.iconsWithTitleMenu = true;
  }

  getInstitutionDataById() {
    console.log("getInstitutionDataById");
    this.institutionStorageResponse = this.storage.get('loginResponse').then((val) => {

      console.log("institutionStorageResponse", val);
      this.institutionUserId = val.id;
      let institution = new Institution();
      institution.id = this.institutionUserId;

      let res: any;
      let InstitutionDataAction: Observable<BackendResponse>;

      InstitutionDataAction = this.backendServiceProvider.getInstitutionDataId(institution.id);
      InstitutionDataAction.subscribe(

        resultArray => {
          if (resultArray.failureData) {
            console.log("InstitutionDataAction - Failure");
            console.log(resultArray.failureData.message)
          }
          else if (resultArray.success) {
            console.log("InstitutionDataAction - success", resultArray);
            res = resultArray.data;
            this.institutionData = res;
          }
          else if (resultArray.networkIssue) {
            console.log('NEtwork Issue');
          }
        })
    })
      .catch(error => {
        console.log('error ' + error);
      })

  }

  getFacultyDataById() {
    //get faculty data from local storage of loginresponse and add it to the faculty model
    //faculty._id send to the backend and get the faculty firstName and lastName
    this.facultyStorageResponse = this.storage.get('loginResponse').then(async (val) => {
      this.facultyUserId = val.id;
      let faculty = new Faculty();
      faculty.firstName = this.facultyFirstName;
      faculty.lastName = this.facultyLastName;
      faculty._id = this.facultyUserId;
      let courseLoginData: any;
      let res: any;
      let facultyDataAction: Observable<BackendResponse>;
      let toastMessage = '';

      let toast = await this.toastController.create({
        message: toastMessage,
        duration: 3000,
        position: 'bottom'
      });
      
      facultyDataAction = this.backendServiceProvider.getFacultyDataId(this.facultyUserId);
      facultyDataAction.subscribe(

        resultArray => {
          if (resultArray.failureData) {
            console.log(resultArray.failureData.message);
            toastMessage = 'Access is denied. Add Valid Email Address.';
            toast.present();
          }
          else if (resultArray.success) {

            res = resultArray.success;
            this.facultyData = res.user;
            console.log('Successfull Fetched Data');

            courseLoginData = res;
            console.log('course data ' + JSON.stringify(courseLoginData));
          }
          else if (resultArray.networkIssue) {
            console.log(resultArray.networkIssue + 'Network Issue');
          }
        })
    })
  }
}
