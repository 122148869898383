// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const ENV = {
  SERVER_NAME: 'https://dev-stp-api.language-arts-press.com/',
  //SERVER_NAME: 'http://18.228.79.98:1667/'
  //SERVER_NAME: 'http://localhost:1337/',
  TGYN_REMOTE_SYNC_URL: "https://685bad27-f016-42ca-8373-22dc7d802c4d-bluemix.cloudant.com/tgyn1",
  TGYN_REMOTE_USER: "tenedingetyrreppecereass",
  TGYN_REMOTE_PASS: "94920e65d7027baf92788fe0725cc5ad22ad7b38",  
  TGYN2_REMOTE_SYNC_URL: "https://685bad27-f016-42ca-8373-22dc7d802c4d-bluemix.cloudant.com/tgyn2r",
  TGYN2_REMOTE_USER: "apikey-8fd1e3fbee9247cfa95fe627b7d0ab5f",  
  TGYN2_REMOTE_PASS: "8ec47e6d98fc571e1fcb12dc90cda01add88335d",
}


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
